import {combineReducers} from 'redux';

import LoaderReducer from './loader/LoaderReducer';
import UserReducer from './user/UserReducer';
import SkillsReducer from './skills/SkillsReducer';

const rootReducer = combineReducers({
  loading: LoaderReducer,
  user: UserReducer,
  skills: SkillsReducer,
});

export default rootReducer;