import {LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, UPDATE_USER, USER} from './UserActions';

const initialState = {
  isLoggedIn: !!localStorage.getItem('token'),
  user: {},
  message: ''
};

const UserReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        isLoggedIn: payload.isLoggedIn,
        user: {...payload.user},
        message: payload.message
      };

    case LOGIN_FAILURE:
      return {
        isLoggedIn: payload.isLoggedIn,
        user: payload.user,
        message: payload.message
      };

    case USER:
      return {
          ...state,
        user: {...payload.user}
      };

    case UPDATE_USER:
      return {
        isLoggedIn: true,
        message: 'User is logged in',
        user: {
          ...state.user,
          ...payload.user
        }
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default UserReducer;