import {toast} from 'react-toastify';
/* REST */
import {GET, POST} from "../../services/rest.service";

/* ACTIONS */
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const USER = 'USER';
export const UPDATE_USER = 'UPDATE_USER';

/* LOGIN */
export const login = (payload) => {
  return dispatch => {
    POST('/admin/auth/user/login', payload)
        .then(({access_token, data}) => {
          localStorage.setItem('token', access_token);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              isLoggedIn: true,
              user: data,
              type: data.type,
              message: 'logged in successfully'
            }
          });
        })
        .catch(err => {
          dispatch({
            type: LOGIN_FAILURE,
            payload: {
              isLoggedIn: false,
              user: {},
              type: undefined,
              message: err.message
            }
          });
          toast.error(err.message);
        });
  };
};

export const getLoggedInUser = () => {
  return dispatch => {
    const url = `/admin/users/me`;
    GET(url)
        .then(res => {
          dispatch({
            type: USER,
            payload: {
              user: res.data,
              type: res.data.type,
            }
          });
        })
        .catch(err => {
          dispatch({
            type: LOGIN_FAILURE,
            payload: {
              user: {},
              type: undefined,
              isLoggedIn: false,
              message: err.message
            }
          });
        })
  }
};

// export const logout = () => {
//   return dispatch => {
//     dispatch();
//   }
// };
