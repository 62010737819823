const dev = {
  api: {
    // URL: 'http://192.168.0.254:5000/v1'
    URL: `https://admin.elitenannies.com/api`
  },
  auth: {
    CLIENT_ID: 'vY6QAr19496Im9nGxMlGXCy4T',
    CLIENT_SECRET: '66W1Tp3M1ZyRQDgCSr4XSv5t97Pyp7875hOCCHV1w3438098zi'
  }
};

const prod = {
  api: {
    URL: '/api'
  },
  auth: {
    CLIENT_ID: 'vY6QAr19496Im9nGxMlGXCy4T',
    CLIENT_SECRET: '66W1Tp3M1ZyRQDgCSr4XSv5t97Pyp7875hOCCHV1w3438098zi'
  }
};

const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
  ...config
};
