import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";

/* REDUX */
import store from './app/redux/store';

import App from './App';

import './vendor';

import * as serviceWorker from './serviceWorker';

const app = (
    <Provider store={store}>
      <App />
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
