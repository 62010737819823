import React, {Component} from 'react';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {cssTransition, toast, ToastContainer} from 'react-toastify';
import history from './app/helpers/history';
// HOCs
import AsyncComponent from "./app/components/hoc/AsyncComponent";
// Components
import LoginComponent from "./app/containers/login/Login";
import AppLoader from "./app/components/loaders/AppLoader";
// import LoaderComponent from "./app/components/other/LoaderComponent";

const AdminLayoutAsync = AsyncComponent(() => import('./app/containers/admin/layout/Layout'));

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        localStorage.getItem('token')
            ? <Component {...props} />
            : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
    )}/>
);

const slide = cssTransition({
  enter: 'fadeIn',
  exit: 'fadeOut',
});

class AppRoutes extends Component {
  render() {
    return (
        <div>
          {this.props.loader.isLoading && <AppLoader title={this.props.loader.title}/>}
          <Router history={history}>
            <Switch>
              <Route path={'/'} exact component={LoginComponent}/>
              <PrivateRoute path={'/admin'} component={AdminLayoutAsync}/>
            </Switch>
          </Router>
          <ToastContainer transition={slide}
                          autoClose={4000}
                          position={toast.POSITION.BOTTOM_CENTER}
                          closeOnClick={true}
                          hideProgressBar={true}/>
        </div>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    loader: state.loading,
  }
};

export default connect(MapStateToProps, null)(AppRoutes);
