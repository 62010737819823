import React, {Component} from 'react';
import {connect} from "react-redux";
import {Formik} from "formik";
import {string, object} from 'yup';

// Images
import banner from '../../../assets/img/illustration.svg';
import TextInput from "../../components/UI/TextInput";

// Services
import {login} from "../../redux/user/UserActions";
import {Redirect} from "react-router-dom";


const LoginForm = (props) => (
  <Formik initialValues={{email: '', password: ''}}
          onSubmit={(values) => {
            props.onSubmit(values);
          }}
          validationSchema={object().shape({
            email: string().email("Enter a valid email").required("Email is required"),
            password: string()
                .min(6, "Password must contain at least 6 characters")
                .required("Enter your password"),
          })}>
    {renderProps => {
      const {values, touched, errors, handleChange, handleBlur, handleSubmit} = renderProps;
      return (
          <form onSubmit={handleSubmit} className="mt-4">
              <TextInput id="email"
                         type="email"
                         size="mb-4"
                         label=""
                         placeholder="Email Address"
                         className="border-0 shadow form-control-lg"
                         error={touched.email && errors.email}
                         value={values.email}
                         onChange={handleChange}
                         onBlur={handleBlur}/>

              <TextInput id="password"
                         type="password"
                         size="mb-4"
                         label=""
                         placeholder="Password"
                         className="border-0 shadow form-control-lg"
                         error={touched.password && errors.password}
                         value={values.password}
                         onChange={handleChange}
                         onBlur={handleBlur}/>

            <button type="submit" className="btn btn-primary shadow px-5">Log in</button>
          </form>
      )
    }}
  </Formik>
);

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLoginSubmit = (formData) => {
    const {dispatch} = this.props;
    dispatch(login(formData))
  };

  render() {
    if (localStorage.getItem('token')) {
      return <Redirect from={`/`} to={`/admin`}/>
    }

    return (
        <div className="page-holder d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center py-5">
              <div className="col-5 col-lg-7 mx-auto mb-5 mb-lg-0">
                <div className="pr-lg-5"><img src={banner} alt="banner" className="img-fluid"/></div>
              </div>
              <div className="col-lg-5 px-lg-4">
                <h1 className="text-base text-primary text-uppercase mb-4">Elite Nannies</h1>
                <h2 className="mb-4">Welcome back!</h2>
                <LoginForm onSubmit={this.handleLoginSubmit}/>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

const MapStateToProps = (state) => {
  return {
    user: state.user
  }
};


export default connect(MapStateToProps, null)(Login);